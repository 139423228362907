import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom"
import styles from './SplashPage.module.sass'

type Props = {
  loggedIn: boolean | string
}

function SplashPage({
  loggedIn
}: Props) {
  return <div className={`${styles.content} ${styles.splashContainer}`}>

    <div className={styles.splash}>
      <Link to='/dashboard'><img src='/bee.jpg' className={`${styles.bee}`} /></Link>
      <Link to='/dashboard' className={`${styles.button} ${styles.splashButton}`}>Go to dashboard</Link>
    </div>
  </div>
}

export default SplashPage
