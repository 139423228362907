import React, { useEffect, useState } from 'react'
import { json } from 'react-router-dom'
import LoginRedirect from '../LoginRedirect/LoginRedirect'
import styles from './ListEvents.module.sass'
type EventData = {
  [key: string]: any
}

type Props = {
  sendMessageToWebsocket: (payload: string) => void
  eventData: EventData
}

type EventProps = {
  lastItem: boolean
  event: EventData
  sendMessageToWebsocket: (payload: string) => void
}

function Event({
  event,
  lastItem,
  sendMessageToWebsocket
}: EventProps) {
  return <div className={`${styles.event} ${!lastItem && styles.eventDivider}`}>
    <h4>{event.EventName}</h4>
    <div className={`${styles.columns} ${styles.fullwidthgap}`}>
      <p>Organisation ID: {event.OrganisationId} </p>
      <button
        className={`${styles.button}`}
        onClick={() => sendMessageToWebsocket(
          JSON.stringify({
            action: "handleCommand",
            subAction: "deleteEvent",
            eventId: event.EventId,
          })
        )}
      >
        delete
      </button>
    </div>
  </div >
}




function ListEvents({
  sendMessageToWebsocket,
  eventData
}: Props) {



  return <div className={`${styles.card}`}>


    <h2>Events</h2>

    {Object.values(eventData).map((event, index) => {
      if (event.active === undefined || event.active === true) {
        return <Event
          key={index}
          event={event}
          sendMessageToWebsocket={sendMessageToWebsocket}
          lastItem={Object.values(eventData).length === (index + 1) ? true : false}
        />
      }
    })}


  </div>

}

export default ListEvents
