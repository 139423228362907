import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom"

import styles from './App.module.sass'
import CloudformationOutputs from './CloudformationOutputs.json'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Dashboard from './components/Dashboard/Dashboard'
import SplashPage from './components/SplashPage/SplashPage'
import NoOrg from './components/noOrgPage/NoOrg'

type Organisations = null | {
  [key: string]: string
}
type EventData = {
  [key: string]: any
}


function App() {
  const WebSocketURI = CloudformationOutputs.WebSocketURI
  const [urlPath, setUrlPath] = useState(window.location.pathname.replace('/', ''))
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [connectionState, setConnectionState] = useState('disconnected')
  const [connectionId, setConnectionId] = useState<string | null>(null)
  const [loggedIn, setLoggedIn] = useState<boolean | string>(false)
  const [username, setUsername] = useState<string | null>(null)
  const [organisations, setOrganisations] = useState<Organisations>(null)
  const [currentOrganisation, setCurrentOrganisation] = useState<string | null>(null)
  const loginUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/login?client_id=${CloudformationOutputs.UserPoolClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/dashboard/`
  const logoutUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/logout?client_id=${CloudformationOutputs.UserPoolClientId}&logout_uri=${window.location.origin}/`
  const [eventData, setEventData] = useState<EventData>({})


  useEffect(() => {
    setConnectionState('tryToConnect')

  }, [])


  useEffect(() => {
    // handle incoming messages
    switch(connectionState) {
      case 'closed':
      case 'error':
        setConnectionState('waitThenReconnect')
        setTimeout(()=>{
          setConnectionState('tryToConnect')
        },2000)
        break;
      case 'tryToConnect':
        setConnectionState('connecting')
        const newSocket = new WebSocket(WebSocketURI)
        newSocket.onopen = () => {
          setSocket(newSocket)
          setConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  socket closed")
          setConnectionState('closed')
        }
        newSocket.onerror = () => {
          setConnectionState('error')
          console.log("🔌 socket error")
        }
      break;

    }

  }, [connectionState])


  useEffect(() => {
    // handle incoming messages
    if (socket !== null) {
      actOnMessage()
    } else {
    }
  }, [socket])

  useEffect(() => {
    const selectedOrgId = window.localStorage.getItem('selectedOrgId')
    if(selectedOrgId) {
      setCurrentOrganisation(selectedOrgId)
    }
  }, [socket])


  const switchCurrentOrganisation = (organisationId: string) => {
    console.log(`Organization id change requested to ${organisationId}`)
    localStorage.setItem("selectedOrgId",organisationId)
    setCurrentOrganisation(organisationId)
  }

  const sendMessageToWebsocket = async (payload: string) => {
    if (socket) {
      //console.log(`sending to websocket: ${payload}`)
      socket.send(payload)
    } else {
      console.log('🚫 Can not send to web socket')
    }
  }

  const actOnMessage = () => {
    if (socket && socket.onmessage === null) {
      socket.onmessage = ({ data }: any) => {
        //console.log(`A message has been received: ${data}`)
        if (data) {
          const action = JSON.parse(data).action
          const payload = JSON.parse(data).payload
          switch (action) {
            case 'updatedEvents':
              //console.log(`📌events table updated: ${JSON.stringify(payload)}`)
              setEventData(payload)
              // to do
              break
            case 'loggedIn':
              setLoggedIn(true)
              setConnectionId(JSON.parse(data).body.connectionId)
              setUsername(JSON.parse(data).body.idTokenDecoded.payload.name)
              setOrganisations(JSON.parse(JSON.parse(data).body.idTokenDecoded.payload.organisationsForUser))

              break
            case 'loginFailed':
              window.location.href = loginUrl
              break
            case 'loginOKButNoOrganisation':
              window.localStorage.setItem('loggedInNoOrgId', data)
              window.location.href='/noorg'
              break
            default:
              console.log(`A message has been received but the action is invalid. ${data}`)
              break
          }
        }
      }
    }
  }







  return <div className={styles.app}>
    <Router>
      <Header
        logoutUrl={logoutUrl}
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        setConnectionId={setConnectionId}
        setOrganisations={setOrganisations}
        connectionState={connectionState}
        setUsername={setUsername}
        connectionId={connectionId}
        organisations={organisations}
        username={username}
        currentOrganisation={currentOrganisation}
        switchCurrentOrganisation={switchCurrentOrganisation}
      />
      <Routes>
        <Route
          path='/dashboard'
          element={
            <Dashboard
              loggedIn={loggedIn}
              loginUrl={loginUrl}
              socket={socket}
              setLoggedIn={setLoggedIn}
              sendMessageToWebsocket={sendMessageToWebsocket}
              currentOrganisation={currentOrganisation}
              eventData={eventData}
            />
          } />


        <Route path='/noOrg'
        element = {
          <NoOrg
            logoutUrl={logoutUrl}
          ></NoOrg>
        }
        >

        </Route>

        <Route
          path='/*'
          element={
            <SplashPage
              loggedIn={loggedIn}
            />
          } />
      </Routes>
      <Footer />
    </Router>
  </div>
}

export default App
