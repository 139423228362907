import React from 'react'
import styles from './Header.module.sass'
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom"

type Organisations = null | {
  [key: string]: string
}

type Props = {
  logoutUrl: string
  loggedIn: boolean | string
  setLoggedIn: React.Dispatch<React.SetStateAction<any>>
  setConnectionId: React.Dispatch<React.SetStateAction<any>>
  setOrganisations: React.Dispatch<React.SetStateAction<any>>
  setUsername: React.Dispatch<React.SetStateAction<any>>
  connectionId: string | null
  organisations: Organisations
  username: string | null
  switchCurrentOrganisation: (organsationId: string) => void
  currentOrganisation: string | null,
  connectionState: string| null, 
}

function Header({
  logoutUrl,
  loggedIn,
  setLoggedIn,
  setConnectionId,
  setOrganisations,
  setUsername,
  connectionId,
  connectionState,
  organisations,
  username,
  switchCurrentOrganisation,
  currentOrganisation
}: Props) {


  const handleLogout = () => {
    console.log('logging out...?')
    setLoggedIn(false)
    setUsername(null)
    setOrganisations(null)
    setConnectionId(null)
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    window.location.href = logoutUrl
  }


  return <div className={styles.header}>

    <div className={`${styles.container}`}>
      <div className={`${styles.columns} ${styles.gap10} ${styles.headerColumns}`}>
        <h1>MingleBee Control Hive</h1>
        <div>
          {connectionState}
          {loggedIn === true ?
            <div>
              <p>✅ {username}</p>
              {
                organisations && Object.keys(organisations).length > 1 && <select value = {currentOrganisation!} onChange={(e) => { switchCurrentOrganisation(e.target.value)}}>
                  {
                    Object.keys(organisations).map((id, index) => {
                      return <option
                      key={index}
                      value={id}
                      >{organisations[id]}</option>
                    })
                  }
                </select>
              }

              {connectionId && <p>{connectionId}</p>}
              <button className={`${styles.button} ${styles.white}`} onClick={handleLogout}>Log out</button>
            </div>
            :
            '❌ Logged out'
          }</div>
      </div>
    </div> </div>
}

export default Header
