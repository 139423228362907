
import styles from './NoOrg.module.sass'
type Props = {
  logoutUrl: string
}



function NoOrg({logoutUrl} : Props)  {
    
  const handleLogout = () => {
    console.log('logging out...?')
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    window.location.href = logoutUrl
  }

  const idTokenJson = window.localStorage.getItem('loggedInNoOrgId') 

  if(idTokenJson === null  ) {
  window.location.href = '/'
  return( <div>Error</div>)
  } else {

  const idToken = JSON.parse(idTokenJson)
  const idDetails = idToken.idTokenDecoded.payload
  window.setTimeout( () => {
    window.localStorage.removeItem('loggedInNoOrgId') 
  }, 1000)
  return <div className={`${styles.content}`}>
    <h1>Your user account does not belong to any organisation</h1>
    <p>Name:  {idDetails.name}, Email: {idDetails.email}</p>

    Wrong account? <button className={`${styles.button} ${styles.white}`} onClick={handleLogout}>Log out</button>


    <p>You need to ask an admin to add you to their org</p>
    
    </div>

}
}
export default NoOrg
