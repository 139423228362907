import React, { useEffect, useState } from 'react'
import LoginRedirect from '../LoginRedirect/LoginRedirect'
import styles from './CreateEvent.module.sass'

type Props = {
  sendMessageToWebsocket: (payload: string) => void
}

type EventData = {
  [key: string]: any
}

function CreateEvent({
  sendMessageToWebsocket
}: Props) {

  const [eventData, setEventData] = useState<EventData>({})
  const [status, setStatus] = useState<string>('new')




  const handleChange = (key: string, value: string) => {
    const newEventData = { ...eventData }
    if (value) {
      newEventData[key] = value
    } else {
      delete newEventData[key]
    }
    setEventData(newEventData)
  }

  const saveEvent = () => {
    const eventRequestId = `eventreq-${Date.now()}`
    console.log('saving event')
    setStatus('saving')
    const payload = JSON.stringify({
      action: "handleCommand",
      subAction: "saveEvent",
      eventData: eventData,
      eventRequestId: eventRequestId
    })
    sendMessageToWebsocket(payload)
  }



  //console.log(eventData)

  return <div className={`${styles.card}`}>

    {/* {status !== 'saving' && */}
      <>
        <h2>Create an event</h2>
        <label htmlFor='eventName'>Event name: </label>
        <input
          id='eventName'
          className={`${styles.textfield} ${styles.fullwidthblock}`}
          value={eventData['eventName'] || ''}
          onChange={(e) => handleChange('eventName', e.target.value)}
        />
        <button
          className={`${styles.button} ${styles.primary}`}
          disabled={eventData['eventName'] && eventData['eventName'] !== '' ? false : true}
          onClick={saveEvent}
        >Save</button>
      </>
    {/* } */}

    {/* {status === 'saving' &&
      <div>
        <img src="/spinner.gif" width='50' />
        <p>Saving...</p>
      </div>
    } */}
  </div>

}

export default CreateEvent
