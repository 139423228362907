import React, { useEffect, useState } from 'react'
import CreateEvent from '../CreateEvent/CreateEvent'
import ListEvents from '../ListEvents/ListEvents'
import LoginRedirect from '../LoginRedirect/LoginRedirect'
import styles from './Dashboard.module.sass'
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom"

type EventData = {
  [key: string]: any
}

type Props = {
  loggedIn: boolean | string
  loginUrl: string
  socket: WebSocket | null
  setLoggedIn: React.Dispatch<React.SetStateAction<string | boolean>>
  sendMessageToWebsocket: (payload: string) => void
  currentOrganisation: string | null,
  eventData: EventData
}

function Dashboard({
  loggedIn,
  loginUrl,
  socket,
  setLoggedIn,
  sendMessageToWebsocket,
  currentOrganisation,
  eventData
}: Props) {

  const [idToken, setIdToken] = useState<string | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    checkForToken()
  }, [])

  useEffect(() => {
    if (idToken && accessToken && socket ) {
      console.log(`sending tokens to websocket!`)
      const payloadObj:{ [index: string]: string }  = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken
      }
      const selectedOrgId =  localStorage.getItem("selectedOrgId")
      if(selectedOrgId) {
        payloadObj['selectedOrgId'] = selectedOrgId
      }
      const payload =  JSON.stringify(payloadObj)
      console.log("** LOGGING IN ** 🪵")
      sendMessageToWebsocket(payload)
    }
  }, [idToken, accessToken, socket, loggedIn, currentOrganisation])




  const checkForToken = () => {
    console.log('Checking for a token')
    const localIdToken = localStorage.getItem("localIdToken")
    const localAccessToken = localStorage.getItem("localAccessToken")
    const queryStringParams = window.location.hash.replace("#", "").split(/[&=]+/)

    let idToken = null
    let accessToken = null

    const indexOfIdToken = queryStringParams.indexOf('id_token')
    if (indexOfIdToken >= 0) {
      idToken = queryStringParams[indexOfIdToken + 1]
    }
    const indexOfAccessToken = queryStringParams.indexOf('access_token')
    if (indexOfAccessToken >= 0) {
      accessToken = queryStringParams[indexOfAccessToken + 1]
    }


    if (idToken && accessToken) {
      setLoggedIn('pending')
      setIdToken(idToken)
      setAccessToken(accessToken)
      localStorage.setItem('localIdToken', idToken)
      localStorage.setItem('localAccessToken', accessToken)
      window.location.href = '/dashboard'

    } else if (localIdToken && localAccessToken) {
      setIdToken(localIdToken)
      setAccessToken(localAccessToken)
    } else {
      window.location.href = loginUrl
    }
  }


  return <div className={`${styles.content}`}>


      {loggedIn === true &&
        <div className={`${styles.container}`}>
          <h1>Dashboard</h1>


        <div className={`${styles.columns} ${styles.fullwidthgap}`}>
          <CreateEvent
          sendMessageToWebsocket={sendMessageToWebsocket}
           />

           <ListEvents
           sendMessageToWebsocket={sendMessageToWebsocket}
           eventData={eventData}
            />
            </div>
          </div>
      }



      {loggedIn === 'pending' &&
        <div className={`${styles.container}`}>
          <img src="/spinner.gif" width='50' />
          <p>logging you in....</p>
          </div>
      }

      {loggedIn === false &&
        <LoginRedirect
          loginUrl={loginUrl
          } />
      }

    </div>

}

export default Dashboard
