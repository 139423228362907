import React, { useEffect, useState } from 'react'
import styles from './LoginRedirect.module.sass'

type Props = {
  loginUrl: string
}

function LoginRedirect({
  loginUrl
}: Props) {
  return <div className={`${styles.container}`}>
    <img src="/spinner.gif" width='50' />
    <p>Redirecting to login page...</p>
    <a href={loginUrl}>Click here if you aren't redirected automatically after 5 seconds</a>
  </div>
}

export default LoginRedirect