import React from 'react'
import styles from './Footer.module.sass'

function Footer() {
  return <div className={`${styles.footer}`}><div className={`${styles.container}`}>
      &copy; Kakapo Labs 2022
   </div> </div>
}

export default Footer
